import { FC } from "react";
import { Form, FormInstance } from "antd";

import {
  LTY_WELC_BONUS_TARGET,
  NIC_LTY_BONUS_W_TIMER_FROM,
  NIC_LTY_BONUS_W_TIMER_PER,
  NIC_LTY_BONUS_W_TIMER_PER_TYPE,
} from "@ni/common/constants";
import { FormValues } from "@ni/common/types";
import { FormContent, NetworkForm, TooltipInfo } from "@ni/common/ui";
import { LoyaltyProgram } from "@ni/sdk/models";

import { RADIO_GROUP_TIMER_FROM, RADIO_GROUP_TIMER_PER_TYPE } from "./WelcomeBonus.constants";

interface WelcomeBonusProps {
  form: FormInstance<FormValues>;
  loyaltyPrograms: LoyaltyProgram[];
}

export const WelcomeBonus: FC<WelcomeBonusProps> = ({ form, loyaltyPrograms }) => {
  const chosenLoyaltyProgram = Form.useWatch(LTY_WELC_BONUS_TARGET, form);

  return (
    <FormContent gap={40}>
      <TooltipInfo
        largeLabel="New customers could receive a certain number of bonus points upon signing up for the loyalty program or after a
        fixed period of time."
        tooltipProps={{
          title:
            "Bonus amount can be redefined for a particular Pricing control table. Please follow the link at the bottom of the page.",
        }}
      />

      <NetworkForm.Select
        formItemOptions={{
          name: LTY_WELC_BONUS_TARGET,
          label: "Choose the loyalty program you want to apply welcome bonus",
          tooltip: "Bonus is added to the point bucket of the appropriate program",
        }}
        optionFilterProp="children"
        optionList={[
          { value: "None", label: "None" },
          ...loyaltyPrograms.map(loyaltyProgram => ({
            value: `${loyaltyProgram?.loyaltyCode?.toUpperCase() || loyaltyProgram?.loyaltyCode}`,
            label: `${loyaltyProgram.loyaltyCode?.toUpperCase()} ${loyaltyProgram.name || ""}`,
          })),
        ]}
      />

      {chosenLoyaltyProgram && chosenLoyaltyProgram !== "None" && (
        <>
          <NetworkForm.Radio
            formItemOptions={{
              name: NIC_LTY_BONUS_W_TIMER_FROM,
              label: "Welcome bonus timer trigger (count down starts from):",
            }}
            radioList={RADIO_GROUP_TIMER_FROM}
          />

          <NetworkForm.Radio
            formItemOptions={{
              name: NIC_LTY_BONUS_W_TIMER_PER_TYPE,
              label: "Welcome bonus count down timer period",
            }}
            radioList={RADIO_GROUP_TIMER_PER_TYPE}
          />

          <NetworkForm.Number
            formItemOptions={{
              name: NIC_LTY_BONUS_W_TIMER_PER,
              label: "Welcome bonus count down timer (number of periods)",
            }}
            min={0}
            precision={0}
          />
        </>
      )}
    </FormContent>
  );
};
